<template>
  <BaseCountdown :time="Number(countdown)" @end="onCountdownEnd">
    <template #default="{ days, hours, minutes, seconds }">
      <div
        class="flex items-center"
        :class="{
          'border border-white rounded-xl px-3 h-14 lg:h-16': !withoutBorders,
        }"
      >
        <div class="flex items-center divide-x divide-alifshop-600">
          <div
            class="px-2 -space-y-0.5 lg:space-y-0 text-center box-content h-5 lg:h-6 flex flex-col justify-center items-center"
            style="min-width: 28px"
          >
            <div class="text-base font-bold leading-5 lg:leading-6 lg:text-xl">
              {{ days.toString().padStart(2, '0') }}
            </div>
            <div class="text-xs leading-4 lg:text-sm">{{ numWord(days, daysLabels) }}</div>
          </div>
          <div
            class="px-2 -space-y-0.5 lg:space-y-0 text-center box-content h-5 lg:h-6 flex flex-col justify-center items-center"
            style="min-width: 28px"
          >
            <div class="text-base font-bold leading-5 lg:leading-6 lg:text-xl">
              {{ hours.toString().padStart(2, '0') }}
            </div>
            <div class="text-xs leading-4 lg:text-sm">{{ numWord(hours, hoursLabels) }}</div>
          </div>
          <div
            class="px-2 -space-y-0.5 lg:space-y-0 text-center box-content h-5 lg:h-6 flex flex-col justify-center items-center"
            style="min-width: 28px"
          >
            <div class="text-base font-bold leading-5 lg:leading-6 lg:text-xl">
              {{ minutes.toString().padStart(2, '0') }}
            </div>
            <div class="text-xs leading-4 lg:text-sm">{{ $t('juma.countdown.minutes') }}</div>
          </div>
          <div
            class="px-2 -space-y-0.5 lg:space-y-0 text-center box-content h-5 lg:h-6 flex flex-col justify-center items-center"
            style="min-width: 28px"
          >
            <div class="text-base font-bold leading-5 lg:leading-6 lg:text-xl">
              {{ seconds.toString().padStart(2, '0') }}
            </div>
            <div class="text-xs leading-4 lg:text-sm">{{ $t('juma.countdown.seconds') }}</div>
          </div>
        </div>
      </div>
    </template>
  </BaseCountdown>
</template>

<script setup>
  import { defineProps, computed, inject, defineEmits, ref } from 'vue';

  import BaseCountdown from '~/components/base/BaseCountdown';
  import { numWord } from '~/utils/numWord';
  import { useI18n } from 'vue-i18n';

  const jumaStartTimestamp = inject('jumaStartTimestamp');
  const jumaEndTimestamp = inject('jumaEndTimestamp');

  const isJumaStarted = ref(+new Date() > Number(jumaStartTimestamp));
  const countdown = computed(() => (isJumaStarted.value ? jumaEndTimestamp : jumaStartTimestamp));

  const emit = defineEmits(['end']);
  defineProps({
    withoutBorders: {
      type: Boolean,
      default: false,
    },
  });

  const { t } = useI18n();

  const daysLabels = computed(() => [
    t('juma.countdown.days[0]'),
    t('juma.countdown.days[1]'),
    t('juma.countdown.days[2]'),
  ]);
  const hoursLabels = computed(() => [
    t('juma.countdown.hours[0]'),
    t('juma.countdown.hours[1]'),
    t('juma.countdown.hours[2]'),
  ]);

  const onCountdownEnd = () => {
    if (!isJumaStarted.value) {
      isJumaStarted.value = true;
      emit('end');
    }
  };
</script>

<script>
  export default {
    name: 'JumaCountdown',
  };
</script>
