export default {
  required: 'Majburiy maydon',
  cardLength: "Karta raqami 16 ta raqamdan iborat bo'lishi kerak",
  cardExpireLength: "Amal qilish muddati 4 ta raqamdan iborat bo'lishi kerak",
  amountMinLength: "Summa {amount} dan ko'p bo'lishi kerak",
  maxValue: 'Maksimal qiymat {number}',
  minValue: 'Minimal qiymat {number}',
  phoneMinLength: "Telefon raqami 12 ta raqamdan iborat bo'lishi kerak",
  smsCodeMinLength: "SMS-kod {number} ta raqamda iborat bo'lishi kerak",
  minPasswordLengthAndOneLatinLetter: 'Kamida 8-ta belgi va bitta lotin harfi',
  passwordNotMath: 'Parollar mos kelmayapti',
  phoneDuplicated: 'Raqam qaytarilmoqda',
  questionnairePhone: 'Telefon raqamni kiritish shart',
  questionnaireName: 'Telefon raqam egasining ismini kiritish shart',
  questionnaireNote: 'Qarindoshlik turini kiritish shart',
  cardExpireWrongDate: "Karta tugash muddati noto'g'ri",
  passportIdLength: "Pasport seriyasi va raqami 9 ta belgidan iborat bo'lishi kerak",
  pinflLength: 'PINFL 14 ta belgidan iborat bo‘lishi kerak',
};
