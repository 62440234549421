export default {
  required: 'Обязательное поле',
  cardLength: 'Номер карты должен состоять из 16 цифр',
  cardExpireLength: 'Срок действия должно состоять из 4 цифр',
  amountMinLength: 'Сумма должна быть больше {amount}',
  maxValue: 'Максимальное значение {number}',
  minValue: 'Минимальное значение {number}',
  maxLength: 'Максимальная длина {number}',
  minLength: 'Минимальная длина {number}',
  phoneMinLength: 'Номер телефона должен состоять из 12 цифр',
  smsCodeMinLength: 'СМС-код должен состоять из {number} цифр',
  minPasswordLengthAndOneLatinLetter: 'Минимум 8 символов и одна латинская буква',
  passwordNotMath: 'Пароли не совпадают',
  phoneDuplicated: 'Номер повторяется',
  questionnairePhone: 'Номер телефона обязательное поле',
  questionnaireName: 'Имя держателя номера обязательное поле',
  questionnaireNote: 'Кем является обязательное поле',
  cardExpireWrongDate: 'Неверная дата истечения карты',
  passportIdLength: 'Серия и номер пасспорта должны состоять из 9 знаков',
  pinflLength: 'ПИНФЛ должен состоять из 14 знаков',
};
