<template>
  <BaseAlifJumaLink v-if="!isJumaEnded">
    <template #default="{ href }">
      <a
        :href="href"
        target="_blank"
        class="juma_promo_countdown text-white w-full relative overflow-hidden flex items-center"
      >
        <img
          :src="OrnamentsIcons"
          alt=""
          class="juma_promo_countdown__ornament absolute right-0 md:right-0 md:left-1/2 top-1/2 transform max-w-none"
        />
        <AtContainer class="z-10 relative h-full">
          <div class="flex items-center justify-between h-full">
            <div
              class="space-y-1 lg:flex lg:items-center lg:space-y-0 lg:gap-x-2"
              :class="[locale === 'uz' ? 'lg:flex-row' : 'lg:flex-row-reverse']"
            >
              <img class="juma_promo_countdown__logo w-full" :src="JumaLogo" alt="alif Juma" />
              <div class="text-xs leading-4 lg:text-xl lg:leading-7 font-medium">
                <template v-if="isJumaStarted">
                  {{ $t('juma.countdown.untilEnd') }}
                </template>
                <template v-else>
                  {{ $t('juma.countdown.untilStart') }}
                </template>
              </div>
            </div>
            <div class="flex items-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
              <JumaCountdown without-borders @end="onCountdownEnd" />
            </div>
            <img class="hidden max-w-full h-full md:block" :src="image" alt="" />
          </div>
        </AtContainer>
      </a>
    </template>
  </BaseAlifJumaLink>
</template>

<script setup>
  import { computed, inject, ref } from 'vue';
  import { useI18n } from 'vue-i18n';

  import JumaLogo from '~/assets/juma-promo-materials/promo-logo.svg';
  import JumaCountdown from '~/components/juma/JumaCountdown.vue';
  import AtContainer from '~/components/ui/AtContainer.vue';
  import OrnamentsIcons from '~/assets/juma-promo-materials/ornaments_2.svg';

  import CountdownImageRu from '~/assets/juma-promo-materials/countdown-image_ru.png';
  import CountdownImageUz from '~/assets/juma-promo-materials/countdown-image_uz.png';
  import BaseAlifJumaLink from '~/components/base/BaseAlifJumaLink.vue';

  const { locale } = useI18n({ useScope: 'global' });

  const image = computed(() => {
    if (locale.value === 'ru') return CountdownImageRu;
    if (locale.value === 'uz') return CountdownImageUz;
    return null;
  });

  const jumaStartTimestamp = inject('jumaStartTimestamp');
  const isJumaStarted = ref(+new Date() > Number(jumaStartTimestamp));
  const jumaEndTimestamp = inject('jumaEndTimestamp');
  const isJumaEnded = ref(+new Date() > Number(jumaEndTimestamp));
  const onCountdownEnd = () => {
    if (!isJumaStarted.value) isJumaStarted.value = true;
  };
</script>

<script>
  export default {
    name: 'BaseJumaPromoBanner',
  };
</script>

<style lang="scss">
  .juma_promo_countdown {
    height: 60px;
    background: radial-gradient(75.56% 1611.85% at 75.56% 50%, #00af66 0%, #06673f 100%);

    @screen md {
      background: radial-gradient(50% 1066.67% at 50% 50%, #00af66 0%, #06673f 100%);
    }
    @screen lg {
      height: 60px;
    }

    &__logo {
      max-width: 88px;

      @screen lg {
        max-width: 98px;
      }
    }

    &__ornament {
      max-width: 325px;
      height: 100%;
      transform: translateX(50px) translateY(-50%);

      @screen md {
        max-width: 500px;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
</style>
