export default {
  day: '{count} kun{postfixUz}',
  month: '{count} oy{postfixUz}',
  fields: {
    pinfl: 'PINFL',
    search: 'Izlash',
    passport: 'Pasport/ID karta seriyasi va raqami',
    phone: 'Telefon raqam',
    password: 'Parol',
    passwordRepeat: 'Parolni qaytaring',
    smsCode: 'SMS-kodni yozing',
    smsCodeExpire: 'Kod amal qilish muddati',
    name: 'Ism',
    birth: {
      date: "Tug'ilgan sana",
      day: 'Kun',
      month: 'Oy',
      year: 'Yil',
    },
    male: 'Erkak',
    female: 'Ayol',
    card: {
      label: 'Karta raqami',
      expires: 'Kartaning muddati',
      payment: "To'lov uchun karta",
      warning: "DIQQAT, karta barcha muddatli to'lovlar uchun o'zgartiriladi",
      mainCardChanged: "Asosiy karta o'zgartirildi",
      cardChangedForAll: 'Karta barcha toʻlovlar uchun oʻzgartirildi',
      expirePlaceholder: 'oo/yy',
    },
    notFound: {
      title: 'Hech narsa topilmadi',
      description: "Sizning so'rovingiz bo'yicha hech narsa topilmadi, boshqa so'z yozib ko'ring",
    },
    selectValue: 'Qiymatni tanlang',
    fileSize: 'Fayl hajmi {value} MB-dan ortiq',
    noName: 'Nomsiz',
  },
  btns: {
    save: 'Saqlash',
    login: 'Kirish',
    register: "Ro'yxatdan o'tish",
    send: 'Yuborish',
    personalCabinet: 'Shaxsiy kabinet',
    repeatSmsSend: 'Qayta jo’natish',
    restorePassword: 'Parolni tiklash',
    getSms: 'Kodni qabul qilish',
    next: 'Davom ettirish',
    setMainCard: 'Asosiy qilish',
    cardIsMain: 'Asosiy karta',
    back: 'Orqaga',
    nextStep: 'Keyin',
    prevStep: 'Ortga',
    loadMore: 'Yuklab olish',
    delete: 'Ochirish',
    change: 'O’zgartirish',
    selectFile: 'Fayl tanlang',
    select: 'Tanlash',
    orDragFile: "yoki o'tkazing",
    profile: {
      settings: 'Profil sozlamalari',
      cards: 'Kartalar boshqaruvi',
      exit: 'Chiqish',
    },
    showAll: 'Hammasini ko’rish',
    rollUp: 'Yopish',
    changePassword: 'Parolni o’zgartirish',
    yes: 'Ha',
    cancel: 'Bekor qilish',
    add: 'Qo’shish',
    understand: 'Tushunarli',
    close: 'Yopish',
    selectDate: 'Sanani tanlang',
    goTelegram: 'Telegramga o’tish',
  },
  titles: {
    gender: 'Jins',
    preferredLanguage: 'Qaysi til bilan sizga murojaat qilish kerak?',
    sendCode: 'Kod {phone} raqamiga yuborildi',
    card: {
      title: "Karta qo'shish",
    },
    sum: 'so’m',
    hello: 'Xush kelibsiz',
    status: {
      title: 'Muddatli to’lov statusi',
      ACTIVE: 'Faol/Aktiv',
      OVERDUE: 'Kechikish',
      PROBLEM: 'Muammoli',
      CLOSED: 'Yopiq',
      PAUSED: 'To’xtatilgan',
      DELETED: 'O’chirilgan ',
      undefined: 'Nomalum',
    },
    yes: 'Ha',
    no: "Yo'q",
  },
  errors: {
    unknown:
      'Ulanish barqaror emas, internetni tekshiring. Agar savolingiz bo\'lsa, <a href="https://t.me/alifazo" target="_blank" class="text-red-400 underline font-bold"> bu yerga </a> murojaat qiling',
    telegram: 'Qandaydir xatolik yuz berdi, iltimos bizga telegram orqali Alif A’zoga murojaat qiling',
  },
  password: {
    maxLength: 'Eng kamida 8 ta belgi',
    oneLowerCase: 'Kamida bitta katta va bitta kichik harf',
    onlyLatin: 'Faqat lotin harflari',
    minOneNumber: 'Kamida bitta raqam',
    minOneSybol: "Kamida bitta qo'shimcha belgi",
  },
  days: ['kun', 'kun', 'kun'],
};
