<template>
  <router-view />
</template>

<script setup>
  import { computed, provide } from 'vue';
  import { useI18n } from 'vue-i18n';

  const { locale } = useI18n();

  const AlifJumaUrl = computed(() => {
    let baseUrl = 'https://juma.alif.uz/';

    if (locale.value === 'ru') {
      baseUrl += 'ru';
    }

    return baseUrl;
  });

  provide('alif_juma_url', AlifJumaUrl);
</script>

<style lang="scss"></style>
