export default {
  titles: {
    merchants: 'Наши партнёры',
    repays_carousel_title: 'Как купить в рассрочку?',
    repays: 'Как погашать рассрочку?',
    sub_merchants: 'У нас более <span class="text-gray-900">250</span> партнеров по всему Узбекистану.',
    goods_categories: 'Покупайте любые товары',
    how_to_azo: 'Как открыть аккаунт',
    create_account: 'Откройте аккаунт Alif Nasiya сейчас',
    sub_create_account: 'Откройте аккаунт и покупайте товары у наших партнёров в рассрочку.',
    faq: 'Частые вопросы',
    sub_faq: 'Не можете найти ответ на свой вопрос? Свяжитесь с нами! Мы всегда готовы ответить на любые вопросы',
    become_partner: 'Станьте нашим партнёром',
    download_app: 'Alif Nasiya в вашем телефоне',
    scan_qr: 'Отсканируйте QR-код, чтобы скачать приложение',
    downloadMobile: 'Загрузите мобильное приложение',
  },
  buttons: {
    register: 'Регистрация',
    login: 'Вход в кабинет',
    see_all_merchants: 'Смотреть все магазины',
    contact_us: 'Связаться с нами',
    download_mobile: 'Загрузить',
  },
  steps: {
    step1: 'Нужны только паспорт и банковская карта',
    step2: 'Оплата больших покупок частями',
    step3: 'Магазины партнеры по всему Узбекистану',
    step4: '0% предоплата',
  },
  repays: {
    repay1_description: 'Скачайте приложение Alif. Заходите в раздел Nasiya, выберите рассрочку и произведите оплату',
    repay1_src_title: 'Скачать',
    repay2_description: 'Через другие мобильные приложения как: Payme, Click',
    repay3_description: 'Через Paynet или любые локальные банки',
  },
  repays_carousel: [
    'Пройдите регистрацию на сайте или в приложении Alif, станьте нашим "аъзо" и получите лимит на покупки',
    'Приходите в магазин наших партнеров и покажите свой лимит. Также можете купить на сайтах, где есть метод оплаты Alif Nasiya',
    'Произведите оплату, заберите товар и оплачивайте частями',
  ],
  categories: {
    appliances: 'Бытовая техника',
    electronics: 'Электроника',
    clothing: 'Одежда, обувь и аксессуары',
    furniture: 'Мебель',
  },
  how_to: {
    step1: 'Зарегистрируйтесь',
    step1_exp: 'Пройдите простую регистрацию на нашем сайте',
    step2: 'Привяжите карту',
    step2_exp: 'Привяжите карту для ежемесячного погашения',
    step3: 'Получите лимит',
    step3_exp: 'Лимит — сумма на которую вы можете совершать покупки',
  },
  faq: {
    title: 'Часто задаваемые вопросы',
    items: [
      {
        question: 'Что такое лимит?',
        answer:
          'Лимит — это ваш баланс для покупки в рассрочку, с которым вы можете совершать покупки на сайте alifshop.uz или у наших партнёров-магазинов без ожидания и дополнительной проверки.',
      },
      {
        question: 'Как узнать свой лимит?',
        answer:
          'Лимит можно проверить сразу после его получения в личном кабинете на сайте alifnasiya.uz или в приложении Alif в разделе Nasiya.',
      },
      {
        question: 'Где совершать покупки?',
        answer:
          'Вы можете совершать покупки онлайн сразу на сайте alifshop.uz или же в магазинах наших партнёров, таких как IDEA, Mediaрark, Elmakon, T-Store и т.д.',
      },
      {
        question: 'Как поступить, если стоимость товара превосходит установленный лимит?',
        answer:
          'Если сумма товара превышает сумму выделенного вам лимита, то мы рассмотрим заявку на выбранный товар и ответим вам в ближайшие минуты.',
      },
      {
        question: 'Как я могу погасить рассрочку?',
        answer:
          'Сумма ежемесячного платежа автоматически списывается с вашей привязанной карты согласно установленному графику платежей. Тем не менее, вы всегда можете самостоятельно осуществить оплату через личный кабинет на сайте alifnasiya.uz или через платёжные системы Alif, Payme, Click и Paynet.',
      },
    ],
  },
  link: {
    how_to_azo: 'Как открыть аккаунт?',
    change_lang: 'O’zbek tiliga o’tish',
  },
  breadcrums: {
    main: 'Главная',
    our_partners: 'Наши партнёры',
  },
  partners: {
    goods_category: 'Категория товара',
    all_category: 'Все категории',
    region: 'Регион',
    store_name: 'Название магазина',
    clear_filter: 'Очистить фильтр',
    store_addresses: 'Адреса магазинов',
    contacts: 'Контакты',
    installment_conditions: 'Условия рассрочки',
    installment_conditions_juma: 'Условия во время Alif Juma',
    duration: 'Срок',
    initial_pay: 'Предоплата',
    percent: 'Наценка',
    no_result: 'По вашему запросу ничего не найдено!',
    month: 'мес.',
    link: 'Перейти на сайт партнёра',
  },
};
