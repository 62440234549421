export default {
  stepsLabels: {
    myid: 'Селфи',
    questionnaire: 'Анкета',
    files: 'Файлы',
    cards: 'Карта',
  },
  myid: {
    title: 'Сделайте селфи',
    description: 'Мы сравним его с фотографией из паспорта',
    resident: 'Я резидент',
    nonResident: 'Я нерезидент',
    request: {
      title: 'Вас долго не было',
      description: 'Пожалуйста, укажите паспортные данные еще раз',
    },
    enterPassportDataTitle: 'Укажите ваши паспортные данные',
    incorrect: {
      title: 'Паспортные данные не совпадают',
      description: 'Пожалуйста, убедитесь, что вы всё указали правильно и попробуйте ещё раз',
    },
    actions: ['Держите телефон прямо перед собой', 'Убедитесь, что ваши глаза отчётливо видны'],
    btns: {
      selfie: 'Сделать селфи',
      start: 'Начать',
      selfieAgain: 'Сфотографировать еще раз',
    },
    notSupported: 'Не поддерживается',
    mediaDeviceError: 'Камера на устройстве не найдена',
    changePhoneFailed: 'Не удалось сменить номер, пожалуйста, обратитесь к Alif A’zo в Телеграм.',
  },
  titles: {
    hello: 'Добро пожаловать!',
    step: 'Шаг {current} из {stepsNumber}',
    first: 'Пожалуйста, заполните анкету перед тем как войти на сайт.',
    files: {
      title: 'Документы',
      choice: 'У вас паспорт или ID карта?',
      sub: 'Загрузите фотографии',
      step: 'Шаг 1/3',
      passport: {
        title: 'Паспорт',
        titleStep: 'Лицевая сторона паспорта',
        info: 'Информация из паспорта и фото, должны быть читабельны',
        example: 'Пример фото паспорта',
      },
      onFace: {
        title: 'Паспорт на фоне лица',
        info: 'Нам важно знать, что вы являетесь держателем паспорта',
        example: 'Пример паспорта на фоне лица',
      },
      showExample: 'Показать пример',
      uploaded: 'Файл успешно загружен',
      docType: 'Выберите тип паспорта',
      correct: 'Нужно так',
      incorrect: 'Так не надо',
      id: {
        card: {
          title: 'ID карта',
          subTitle: [
            'Информация из ID карты и фото, должны быть читабельны',
            'Сфотографируйте обе стороны вашей ID карты по очереди',
          ],
          files: {
            front: 'Фото передней части ID-карты:',
            back: 'Фото задней части ID-карты:',
          },
          example: 'Пример фото ID карты',
        },
        onFace: {
          title: 'ID карта на фоне лица',
          subTitle: 'Нам важно знать, что вы являетесь держателем карты',
          example: 'Пример ID карты на фоне лица',
        },
        registration: {
          title: 'Прописка (не обязательно)',
          subTitle: 'Информация из прописки, должна быть читабельна',
          example: 'Пример фото прописки',
        },
      },
    },
    card: {
      title: 'Карта',
      sub: 'Привяжите карту вашего основного зароботка',
      step: 'Шаг 2/3',
    },
    questionnaire: {
      title: 'Анкета',
      sub: 'Пожалуйста, заполните все поля',
      step: 'Шаг 3/3',
      contacts: {
        title: 'Пожалуйста, добавьте контакты ваших родственников',
        addContact: 'Добавить контакт №{number}',
        contact: 'Контакт {number}',
      },
    },
    personalInformation: 'Личная информация',
    job: 'Информация о работе',
    jobInfo: 'Введите полное юридическое наименование организации',
    other: 'Прочее',
    credit: ['Есть ли у вас сейчас кредит?', 'Да', 'Нет'],
    phone: 'Номер родственника: {number}',
    relationDegreeInfo: 'Например: муж/жена, родитель, брат/сестра',
    completed: {
      title: 'Поздравляем!',
      info: 'Вы прошли все необходимые шаги.',
      sub: 'Наши менеджеры уже приступили к просмотру ваших данных и в ближайшее время вам будут доступы другие разделы.',
    },
    documentType: 'Выберите свой тип документа',
    chooseIfPassport: 'Выберите этот вариант, если у вас паспорт',
    chooseIfId: 'Выберите этот вариант, если у вас ID карта',
    thx: {
      title: 'Вы завершили регистрацию',
      msg: ['Ваша заявка сейчас на рассмотрении.', 'Ответ получите в виде смс на номер', 'в течение 5 минут'],
    },
  },
  fields: {
    card: 'Данные карты',
    cardInfo: 'Вы сможете добавить дополные карты в личном кабинете',
    workplace: 'Где вы работаете?',
    profession: 'Должность',
    income: 'Доход за месяц',
    sum: 'сум',
    relativeName: 'Имя держателя номера',
    relationDegree: 'Кем является',
    activityArea: 'Сфера деятельности',
    preferredLanguage: 'Язык',
  },
  btns: {
    goQuestionnaire: 'Перейти к анкете',
    completed: {
      goBack: 'Вернуться к анкете',
    },
    passport: 'У меня паспорт',
    idCard: 'У меня ID карта',
    additionalCard: 'Привязать дополнительную карту',
    goCabinet: 'Перейти в кабинет',
    goStore: 'Вернуться в магазин',
    loadPhoto: 'Загрузить фото',
    sendRequest: 'Отправить запрос на рассмотрение',
  },
  steps: {
    documents: 'Документы',
    documentsInfo: 'Паспорт',
    card: 'Карта',
    cardInfo: 'Банковская карта',
    questionnaire: 'Анкета пользователя',
    questionnaireInfo: 'Информация о себе',
    phonesInfo: 'Дополнительные контакты',
    stepEnd: 'Все шаги заполнены',
  },
  questionnaire: {
    activityAreaFiles: {
      title: 'Увеличьте свой лимит',
      clear: 'Понятно',
      showExample: 'Показать пример',
      exampleTaxiTitle: 'Пример фотографии доходов',
      taxi: 'Скриншоты дохода из приложений для таксистов помогут увеличить лимит на рассрочку',
      military: 'Пожалуйста, загрузите своё военное удостоверение',
    },
  },
  updatePassportId: {
    failedText: 'Клиент с данной серией паспорта уже существует. Пожалуйста, обратитесь к Alif A’zo в Телеграм.',
    title: 'Клиент с данным номером телефона уже существует',
    description: 'Обновите паспортные данные, чтобы продолжить',
    buttons: {
      submit: 'Изменить паспортные данные',
      back: 'Назад',
    },
  },
  cards: {
    repeatCodeSuccess: 'Код успешно отправлен на {phone}',
  },
};
