import VueCookies from 'vue-cookies';
import { createRouter, createWebHistory } from 'vue-router';

import * as Sentry from '@sentry/vue';

import { AUTH_COMPONENT_NAME } from '~/constants';
import { availableLocales } from '~/constants/config';

import { useDeviceProfiler } from '~/hooks/useDeviceProfiler';

import AppLayout from '~/layouts/AppLayout.vue';
import InnerLayout from '~/layouts/InnerLayout.vue';

import i18n from '~/plugins/i18n';

import { getToken } from '~/services/apiClient';
import { setApiLocaleHeader } from '~/services/apiClient';
import { getUser } from '~/services/auth.api';
import { getMe } from '~/services/profile.api';

import { $_at_user } from '~/shared/at-user';

import { removeHttp } from '~/utils/removeHttp';

import LandingPage from '~/views/LandingPage.vue';

import appRoutes from './app';
import authRoutes from './auth';
import formsRoutes from './forms';

const routes = [
  ...authRoutes,
  {
    path: '/:locale(ru|uz)?',
    component: InnerLayout,
    children: [
      {
        path: '',
        component: AppLayout,
        children: [
          {
            path: '',
            name: 'Landing',
            component: LandingPage,
            meta: { public: true },
          },
          {
            path: 'shops',
            name: 'Shops.Index',
            component: () => import('~/views/shops/ShopsIndexPage.vue'),
            meta: { public: true },
          },
          {
            path: 'shops/:shopId',
            name: 'Shops.Show',
            props: true,
            component: () => import('~/views/shops/ShopsShowPage.vue'),
            meta: { public: true },
          },
        ],
      },
      ...formsRoutes,
      ...appRoutes,
    ],
  },
  {
    path: '/:locale(ru|uz)?/juma',
    name: 'Juma',
    component: () => import('~/views/JumaRedirectPage.vue'),
    meta: {
      public: true,
      title: "alif Juma — O'zbekistonda muddatli to'lovga pasaytirilgan narxlar",
      description:
        "alif Juma. Boshlang'ich to'lov — 0 so'm. HALOL. Saytga o'ting va mahsulotlarni muddatli to'lovga sotib olish uchun limit oling. O‘zbekiston bo‘ylab 2200 dan ortiq hamkor do‘konlar ishtirok etadi. Komissiya 2 barobarga pasayadi. To'lov muddati 6 oydan 24 oygacha bo'ladi.",
    },
  },
  {
    path: '/:locale(ru|uz)?/home',
    redirect: { path: '' },
  },
  {
    path: '/forbidden',
    name: 'error403',
    component: () => import(`~/views/ForbiddenPage.vue`),
    meta: { title: 'Доступ запрещен', public: true, error: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'error404',
    component: () => import(`~/views/NotFoundPage.vue`),
    meta: { title: 'Страница не найдена', public: true, error: true },
  },
];

export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
  routes,
});

router.beforeEach(async (to, from) => {
  document.title = to?.meta?.title
    ? to.meta.title
    : `Alif Nasiya — mahsulotlarni 24 oygacha muddatli to'lovga berish xizmati `;
  if (to.meta?.description)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description);
  const isPublic = to.meta?.public ?? false;
  const hasToken = getToken() && getToken() !== '';

  const cookieLocale = VueCookies.get('lang');
  const queryLocale = to.params.locale || cookieLocale || availableLocales[0];

  if (to.query.locale && to.query.locale !== to.params.locale) {
    return { ...to, params: { ...to.params, locale: to.query.locale }, query: { ...to.query, locale: undefined } };
  }
  if (
    Object.prototype.hasOwnProperty.call(to.params, 'locale') ||
    Object.prototype.hasOwnProperty.call(from.params, 'locale')
  ) {
    if (
      (!to.params.locale || !availableLocales.includes(to.params.locale)) &&
      (!from.params.locale || !availableLocales.includes(from.params.locale))
    ) {
      return { ...to, params: { ...to.params, locale: queryLocale } };
    }
    if (!to.params.locale) {
      return { ...to, params: { ...to.params, locale: from.params.locale } };
    }
  }

  i18n.global.locale.value = queryLocale;
  setApiLocaleHeader(i18n.global.locale.value);
  VueCookies.set('lang', queryLocale);

  if (to.query.source) {
    sessionStorage.setItem('source', removeHttp(to.query.source.toString()));
  }
  if (to.query.offer_id && to.query.condition_id) {
    sessionStorage.setItem('offer_id', to.query.offer_id);
    sessionStorage.setItem('condition_id', to.query.condition_id);
  }

  /**
   * In case when page is not public and user did not auth.
   */
  if (!isPublic && !hasToken) {
    const prevUrl = to.fullPath;
    return {
      name: AUTH_COMPONENT_NAME,
      query: { ...(prevUrl !== '/' ? { from: prevUrl } : {}) },
      params: { locale: queryLocale },
    };
  }

  if (hasToken) {
    const currentUser = $_at_user.value;
    if (!currentUser) {
      const getShortName = (name, surname) => {
        const _name = name ?? '';
        const _surname = surname ?? '';
        return (_name?.[0]?.toUpperCase() ?? '') + (_surname?.[0]?.toUpperCase() ?? '');
      };
      const [authUser, me] = await Promise.all([getUser(), getMe()]);

      const authUserData = authUser?.data?.data || {};
      const meData = me?.data || {};

      if (!VueCookies.get('lang') && meData?.preferred_language && !queryLocale) {
        i18n.global.locale.value = meData?.preferred_language;
        setApiLocaleHeader(i18n.global.locale.value);
      }

      $_at_user.value = {
        ...meData,
        userId: authUserData?.data?.id,
        clientId: meData.id,
        avatar_link: authUserData?.data?.avatar_link,
        shortName: getShortName(meData?.name, meData?.surname),
      };

      Sentry.setUser({ id: $_at_user.value.clientId });

      const deviceProfiler = useDeviceProfiler();
      if (deviceProfiler.isCollectAllowed.value) {
        deviceProfiler.collect(meData.id).then(deviceProfiler.denyCollect);
      }
    }
  }
  const isRegistered = $_at_user.value?.is_identified || $_at_user.value?.status_key === 'REVIEWED' || false;

  /**
   * If user auth redirect from LOGIN page && TODO - REGISTER page
   */
  if (hasToken && to.matched.some(route => route.name?.includes('Auth'))) {
    if (isRegistered) {
      return { name: 'App.Index', params: { locale: queryLocale } };
    }
  }

  if (hasToken && !isPublic) {
    if (!isRegistered) {
      const hasForm = to.meta?.form;
      if (!hasForm && to.name !== 'Forms') {
        return { name: 'Forms', params: { locale: queryLocale } };
      }
    } else if (to.meta?.form) {
      return { name: 'App.Index', params: { locale: queryLocale } };
    }
  }
  return true;
});

export default router;
