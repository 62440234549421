export default {
  titles: {
    merchants: `Bizning hamkorlar`,
    repays_carousel_title: 'Muddatli to’lovga qanday sotib olsa bo’ladi?',
    repays: 'To’lovni qanday amalga oshirish mumkin?',
    sub_merchants: `Bizda <span class="text-gray-900">250</span> dan ortiq hamkorlar butun O'zbekiston bo'ylab.`,
    goods_categories: 'Istalgan tovarni xarid qiling',
    how_to_azo: `Shaxsiy sahifa qanday ochiladi`,
    create_account: 'Alif Nasiya sahifasini hozir oching',
    sub_create_account: `Shaxsiy sahifangizni oching va bizning hamkorlarimizdan muddatli to'lov uchun tovar xarid qiling`,
    faq: "Ko'p beriladigan savollar",
    sub_faq: `Savollaringizga javob topa olmayapsizmi? Biz bilan bog'laning! Har bir savollaringizga javob berishga tayyormiz`,
    become_partner: "Bizning hamkorimiz bo'ling",
    download_app: 'Alif Nasiya sizning smartfoningizda',
    scan_qr: 'Ilovani yuklab olish uchun, QR-kodni skaner qiling',
    downloadMobile: 'Mobil ilovani yuklab oling',
  },
  buttons: {
    register: "Ro'yxatdan o'ting",
    login: 'Shaxsiy kabinet',
    see_all_merchants: `Barcha do'konlarni ko'rish`,
    contact_us: "Biz bilan bog'lanish",
    download_mobile: 'Yuklab olish',
  },
  steps: {
    step1: 'Faqatgina pasport va bank kartasi kerak bo’ladi',
    step2: 'Katta mahsulotlarga to’lovlarni bo’lib to’lash',
    step3: 'O’zbekiston bo’ylab hamkor do’konlar',
    step4: 'Boshlang’ich to’lov 0%',
  },
  repays: {
    repay1_description:
      'Alif ilovasini yuklab oling. Nasiya bo’limiga o’ting, bo’lib to’lash rejasini tanlang va to’lovni amalga oshiring',
    repay1_src_title: 'Yuklab olish',
    repay2_description: 'Payme yoki Click kabi boshqa mobil ilovalar orqali to’lov amalga oshirishingiz mumkin',
    repay3_description: 'Paynet yoki boshqa mahalliy banklar',
  },
  repays_carousel: [
    'Saytda yoki Alif ilovasida ro’yxatdan o’ting, “azo”yimizga aylaning va xaridlar uchun limitga ega bo’ling',
    'Hamkorlarimiz do’koniga keling va limitingizni ko’rsating. Shuningdek, Alif Nasiya to’lov usuli mavjud saytlarda ham xarid qilishingiz mumkin',
    'To’lovni amalga oshiring, tovarni oling va bo’lib to’lang',
  ],
  categories: {
    appliances: 'Maishiy texnika',
    electronics: 'Elektronika',
    clothing: 'Kiyim, oyoq kiyim va aksessuarlar',
    furniture: 'Mebel',
  },
  how_to: {
    step1: "Ro'yxatdan o'ting",
    step1_exp: `Saytimiz orqali oson ro'yxatdan o'ting`,
    step2: `Kartangizni ulang`,
    step2_exp: `Oylik to'lov uchun kartangizni ulang`,
    step3: `Limit oling`,
    step3_exp: `Limit — xarid uchun summa miqdori`,
  },
  faq: {
    title: "Tez-tez so'raladigan savollar",
    items: [
      {
        question: 'Limit bu nima?',
        answer:
          "Limit — ro'yxatdan o'tgandan keyin muddatli to'lovga mahsulot sotib olish uchun taqdim etiladigan balans. Uning yordamida siz tovarlarni ortiqcha kutmasdan alifshop.uz saytida yoki hamkorlarimiz do‘konlarida sotib olishingiz mumkin.",
      },
      {
        question: 'Limitni qanday tekshirish mumkin?',
        answer:
          "Limit olganingizdan so'ng, uni alifnasiya.uz saytidagi shaxsiy kabinetingizda yoki Alif ilovasidagi Nasiya bo‘limida tekshirishingiz mumkin.",
      },
      {
        question: 'Qayerdan xarid qilish mumkin?',
        answer:
          "Siz alifshop.uz saytida yoki IDEA, Mediapark, Elmakon, Goodzone va boshqalar kabi hamkorlarimiz do'konlarida xarid qilishingiz mumkin.",
      },
      {
        question: 'Mahsulot narxi menga berilgan limit miqdoridan oshsa nima qilish kerak?',
        answer:
          "Agar mahsulot narxi sizga berilgan limit miqdoridan oshgan bo'lsa, biz ushbu mahsulotga arizani bir necha daqiqa ichida ko'rib chiqib sizga javob beramiz.",
      },
      {
        question: "Oylik to'lovni qanday amalga oshirish mumkin?",
        answer:
          "Har oy bog'langan kartadan muddatli to'lovning belgilangan miqdori yechib olinadi. Va siz mustaqil ravishda alifnasiya.uz saytidagi shaxsiy kabinetingiz yoki Alif, Payme, Click va Paynet to‘lov tizimlari orqali to‘lovni amalga oshirishingiz mumkin.",
      },
    ],
  },
  link: {
    how_to_azo: `Shaxsiy sahifa qanday ochiladi?`,
    change_lang: 'Перейти на русский',
  },
  breadcrums: {
    main: 'Asosiy',
    our_partners: 'Bizning hamkorlar',
  },
  partners: {
    goods_category: 'Tovar turi',
    region: 'Hududlar',
    store_name: `Do'kon nomi`,
    clear_filter: 'Filterni tozalash',
    store_addresses: `Do'konlar manzillari`,
    contacts: 'Kontaktlar',
    installment_conditions: `Muddatli to'lov shartlari`,
    installment_conditions_juma: "Alif Juma vaqtida muddatli to'lov shartlari",
    duration: 'Muddat',
    initial_pay: `Oldindan to'lov`,
    percent: `Ustama qiymat`,
    all_category: 'Barcha tovarlar',
    no_result: `Sizning qidiruvingiz bo'yicha hech narsa topilmadi!`,
    month: 'oy',
    link: "Hamkor saytiga o'ting",
  },
};
